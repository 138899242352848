import { useLocation } from 'react-router-dom';
import scrollToSection from '../components/ScrollToSection'
function Navbar() {
	const location = useLocation();


	return (
		<nav className='flex items-center justify-center flex-wrap bg-black p-2 w-full z-10 top-0'>
			<div className='flex items-center flex-shrink-0 text-white mr-6'>
				<a href='/'>
					<span className='self-center text-1xl font-semibold whitespace-nowrap'>altaaf.dev</span>
				</a>
			</div>
			<div className='w-full block lg:flex lg:items-center lg:w-auto'>
				<div className='text-sm lg:flex-grow mx-auto'>
				<a
					onClick={(event) => scrollToSection(event, 'Projects')}
					href='/#Projects'
					className='block mt-4 lg:inline-block lg:mt-0 text-white hover:text-white mr-8'>
					Projects
				</a>
				<a
					onClick={(event) => scrollToSection(event, 'Contact')}
					href='/#Contact'
					className='block mt-4 lg:inline-block lg:mt-0 text-white hover:text-white mr-8'>
					Contact
				</a>
				<a
					href='/assets/documents/Resume.pdf'
					target='_blank'
					rel='noreferrer'
					className='block mt-4 lg:inline-block lg:mt-0 text-white hover:text-white mr-8'>
					Resume
				</a>

				</div>
			</div>
		</nav>
	);
}

export default Navbar;
