import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ParticlesBackground from './components/ParticlesBackground';
import NotFoundPage from './pages/NotFoundPage';
const Router = () => {
	return (
		<BrowserRouter>
			<Navbar />
			<ParticlesBackground />
			<Routes>
				<Route path='/' element={<HomePage />} />
				<Route path='/About' element={<HomePage />} />
				<Route path='*' element={<NotFoundPage />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	);
};

export default Router;
