const scrollToSection = (event, sectionId) => {
	event.preventDefault(); // Prevent default anchor behavior
	const target = document.getElementById(sectionId); // Get the target section by ID
	if (target) {
		const targetPosition = target.getBoundingClientRect().top + window.pageYOffset; // Get the target position
		const startPosition = window.pageYOffset; // Get current position
		const distance = targetPosition - startPosition; // Calculate distance
		const duration = 800; // Set duration in milliseconds
		let startTime = null;

		const animation = (currentTime) => {
			if (startTime === null) startTime = currentTime; // Initialize start time
			const timeElapsed = currentTime - startTime; // Calculate elapsed time
			const progress = Math.min(timeElapsed / duration, 1); // Calculate progress

			// Easing function (ease-in-out)
			const ease = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

			window.scrollTo(0, startPosition + distance * ease(progress)); // Scroll to position

			if (progress < 1) {
				requestAnimationFrame(animation); // Continue the animation
			}
		};

		requestAnimationFrame(animation); // Start the animation
	}
};

export default scrollToSection;
