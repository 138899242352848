import React from 'react';
import Typewriter from 'typewriter-effect';
import scrollToSection from '../../components/ScrollToSection'
const Landing = () => {
	const Links = [
		{
			Name: 'Resume',
			img: '/assets/icons/resume.png',
			href: '/assets/documents/Resume.pdf',
		},
		{
			Name: 'Github',
			img: '/assets/icons/github.png',
			href: 'https://github.com/Altaaaf',
		},
		{
			Name: 'LinkedIn',
			img: '/assets/icons/linkedin.png',
			href: 'https://www.linkedin.com/in/altaafali/',
		},
		{
			Name: 'Email',
			img: '/assets/icons/email.png',
			href: 'mailto:Altaaf-Ali@outlook.com',
		},
	];
	return (
		<section
			id='Landing'
			className='min-h-screen bg-black flex justify-center items-center'>
			<div className='text-center text-white'>
				<h1 className='text-7xl font-bold mb-4'>Altaaf Ali</h1>
				<p className='text-2xl mb-7'>
					<Typewriter
						options={{
							strings: [
								"Software Engineer",
								"Full-Stack Developer",
								"Data Analyst",
							],
							autoStart: true,
							loop: true,
							delay: 75,
						}}
					/>
				</p>
				<div className='flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 p-4'>
					{Links.map((link) => (
						<a
							href={link.href}
							target='_blank'
							rel='noreferrer'
							key={link.Name}
							className='bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow flex items-center'>
							<img src={link.img} alt='' className='mr-3 w-6 h-6 object-contain' />
							<span className='text-lg'>{link.Name}</span>
						</a>
					))}
				</div>
				<div className='flex justify-center mt-20'>
					<a
						onClick={(event) => scrollToSection(event, 'Projects')}
						href='#Projects'
						className='bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full shadow flex items-center animate-bounce'
					>
						<img src='/assets/images/arrow_down.png' alt='' className='mr-3 w-6 h-6 object-contain' />
						<span className='text-lg'>Projects</span>
					</a>
				</div>
			</div>
		</section>
	);
	
};

export default Landing;
