import ContactForm from './ContactForm';
import Landing from './Landing';
import Langauges from './Languages';
import CardGrid from './CardGrid';
const HomePage = () => {
	return (
		<div>
			<Landing />
			<Langauges />
			<CardGrid />
			<ContactForm />
		</div>
	);
};

export default HomePage;
