import { CheckCircleIcon } from '@heroicons/react/24/solid';

const Card = ({ title, image, description, url, index, visible, features, tecnologies }) => {
	const repositoryElement = url ? (
		<a
			href={url}
			target='_blank'
			rel='noreferrer'>
			<button className='mt-4 bg-blue-900 text-white py-2 px-4 rounded-full transition-all duration-300 transform hover:bg-blue-500 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50'>
				Visit Project Repository
			</button>
		</a>
	) : (
		<button className='mt-4 bg-red-900 text-white py-2 px-4 rounded-full transition-all duration-300 transform cursor-not-allowed'>
			Private Repository
		</button>
	);

	return (
		<div 
			className={`project bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105 transition-opacity duration-500 w-80 ${visible ? "animate-fadeIn" : "opacity-0"}`}
			data-index={index}
			style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
		>
			<a href={image} target='_blank' rel='noopener noreferrer'>
				<img
					className='w-full h-32 object-cover'
					src={image}
					alt=''
					style={{ transform: 'scale(1)', transition: 'transform .2s ease-in-out' }}
					onMouseOver={(e) => {
						e.currentTarget.style.transform = 'scale(0.95)';
					}}
					onMouseOut={(e) => {
						e.currentTarget.style.transform = 'scale(1)';
					}}
				/>
			</a>
			<div className="p-4 text-center flex-grow">
				<h2 className="text-2xl font-bold text-blue-900">{title}</h2>
				<p className="mt-3 text-blue-900 leading-normal">{description}</p>
				<p className="mt-3 text-left text-lg text-blue-900 font-bold leading-normal">Features:</p>
				<ul className="mt-1 list-none text-left text-blue-900 pl-8">
					{features.map((feature, index) => (
						<li key={index} className="flex items-center mb-2">
							<CheckCircleIcon className="h-5 w-5 text-blue-900 mr-2 flex-shrink-0" />
							<span>{feature}</span>
						</li>
					))}
				</ul>
				<p className="mt-3 text-left text-lg text-blue-900 font-bold leading-normal">Technologies:</p>
				<ul className="mt-1 list-none text-left text-blue-900 pl-8">
					{tecnologies.map((technology, index) => (
						<li key={index} className="flex items-center mb-2">
							<CheckCircleIcon className="h-5 w-5 text-blue-900 mr-2 flex-shrink-0" />
							<span>{technology}</span>
						</li>
					))}
				</ul>
			</div>
			<div className="flex justify-center mb-4">
				{repositoryElement}
			</div>
		</div>
	);
};

export default Card;
