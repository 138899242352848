// src/CardGrid.js
import React, { useState, useEffect } from 'react';
import Card from '../../components/Card';

const CardGrid = () => {
  const CardData = [
    {
        title: 'Account Manager',
        image: '/assets/projects/account_manager.png',
        description:'Python application that allows you to securely store and retrieve account information.',
        url: 'https://github.com/Altaaaf/Account-Manager',
        features: [
          "AES-256-GCM Encryption",
          "Automatic database backups",
        ],
        tecnologies: ["Python", "SQLAlchemy", "PyQt5"]
    },
    {
        title: 'Proxy Checker',
        image: '/assets/projects/proxy_checker.gif',
        description:'Fast, lightweight proxy checker.',
        url: 'https://github.com/Altaaaf/Proxy-Checker',
        features: [
          "Asynchronous",
          "Filter duplicate and invalid proxies",
          "Retry if proxy times out",
          "Supports HTTP, HTTPS, SOCKS4, SOCKS5 protocols"

        ],
        tecnologies: ["Python", "SQL"]
    },
    {
        title: 'SQLite Visualizer',
        image: '/assets/projects/sqlite_visualizer.gif',
        description:'Python GUI application tool to visualize and modify SQLite databases.',
        url: 'https://github.com/Altaaaf/SQLite-Visualizer',
        features: [
          "Interactive GUI",
          "Supports all SQL commands",
          "Supports advanced queries",
          "Supports all CRUD operations",
          "Customizable themes"
        ],
        tecnologies: ["Python", "SQL", "PyQt5"]
    },
    {
        title: 'Omegle Chat Bot',
        image: '/assets/projects/omegle_chatbot.gif',
        description:'Application to create realistic conversation with users.',
        features: ["Multi-Threading", "Supports all languages", "Bypasses bot detection security", "Detailed logging"],
        tecnologies: ["Python", "Javascript", "SQL", "Selenium", "Natural Language Processing"]
    },
    {
        title: 'Account Generator',
        image: '/assets/projects/account_generator.png',
        description:'Mass creates accounts on a popular gaming website.',
        features: [
          "Multi-Threading",
          "Supports email verification",
          "Bypasses CAPTCHA",
          "Bypasses game security",
          "Detailed logging"
        ],
        tecnologies: ["Python", "Javascript", "SQL", "Requests"]
    },
    {
        title: 'Watermarker Tool',
        image: '/assets/projects/image_watermarker.gif',
        description:'Python script to add watermark logos to images.',
        url: 'https://github.com/Altaaaf/Image-watermarker',
        features: ["Supports image resizing", "Customizable settings"],
        tecnologies: ["Python"]
    },
    {
        title: 'Crypto Price Tracker',
        image: '/assets/projects/crypto_price_tracker.gif',
        description:'A tool made using Go to track the price of any cryptocurrency tokens.',
        url: 'https://github.com/Altaaaf/Crypto-currency-price-tracker',
        features: [
          "Supports all tokens",
          "Customizable alert sound"
        ],
        tecnologies: ["Go", "http"]
    },
    {
        title: 'Youtube Thumbnail Downloader',
        image: '/assets/projects/thumbnail_downloader.gif',
        description:'A tool made using Go to download thumbnails for any given YouTube video url.',
        url: 'https://github.com/Altaaaf/Youtube-Thumbnail-Downloader',
        features: ["Supports all resolutions"],
        tecnologies: ["Go", "http"]
    },
    {
        title: 'Movie Reviewer',
        image: '/assets/projects/movie_reviewer.gif',
        description:'Python flask project which allows users to share their thoughts on a movie.',
        url: 'https://github.com/Altaaaf/Movie-Reviewer',
        features: [
          "Supports seraching by movie name or IMDb ID",
          "Built in webscraping for relevant movie details",
          "Post and view comments on any movie",
        ],
        tecnologies: [
          "Python",
          "Flask",
          "SQLite3",
          "Requests",
          "BeautifulSoup"
        ]
    },
  ];

  const [visible, setVisible] = useState(Array(CardData.length).fill(false));

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const index = entry.target.getAttribute('data-index');

        if (entry.isIntersecting) {
          // Card is visible
          setVisible((prev) => {
            const newVisible = [...prev];
            newVisible[index] = true; // Mark as visible
            return newVisible;
          });
          observer.unobserve(entry.target); // Stop observing once visible
        } else {
          // Card is not visible
          setVisible((prev) => {
            const newVisible = [...prev];
            newVisible[index] = false; // Mark as not visible
            return newVisible;
          });
          observer.observe(entry.target); // Re-observe when it goes out of view
        }
      });
    });

    // Observe each project
    const elements = document.querySelectorAll('.project');
    elements.forEach((el) => observer.observe(el));

    return () => {
      // Cleanup observer on unmount
      elements.forEach((el) => observer.unobserve(el));
    };
  }, [CardData]);

  return (
    <section id="Projects" className="pt-10 min-h-screen bg-gray">
        <div className="text-center text-blue-900">
            <h1 className="mb-4 text-4xl font-bold tracking-tight leading-none md:text-5xl lg:text-6xl">
                Projects
            </h1>
            <p className="mb-8 text-lg font-normal">
                Some projects that I've previously worked on - find more on my GitHub page
            </p>
        </div>
        <div className="flex justify-center p-4"> {/* Center the grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {CardData.map((card, index) => (
                    <Card
                        key={index}
                        title={card.title}
                        image={card.image}
                        description={card.description}
                        url={card.url}
                        index={index}
                        visible={visible[index]}
                        features={card.features}
                        tecnologies={card.tecnologies}
                    />
                ))}
            </div>
        </div>
    </section>
  );
};

export default CardGrid;
