// src/components/Footer.js
import React from 'react';
import scrollToSection from './ScrollToSection';
const Footer = () => {
    return (
        <footer className="bg-blue-900 text-white py-10">
            <div className="container mx-auto px-6">
                <div className="flex flex-col md:flex-row justify-between">
                    <div className="mb-6 md:mb-0">
                        <h2 className="text-2xl font-bold mb-4">altaaf.dev</h2>
                        <p className="text-white"></p>
                    </div>
                    <div className="mb-6 md:mb-0">
                        <h3 className="text-lg font-semibold mb-2">Quick Links</h3>
                        <ul>
                            <li><a onClick={(event) => scrollToSection(event, 'Landing')} href="#Landing" className="text-white hover:text-gray-400">Home</a></li>
                            <li><a onClick={(event) => scrollToSection(event, 'Projects')} href="#Projects" className="text-white hover:text-gray-400">Projects</a></li>
                            <li><a onClick={(event) => scrollToSection(event, 'Contact')} href="#Contact" className="text-white hover:text-gray-400">Contact</a></li>
							<li><a href="/assets/documents/Resume.pdf" target='_blank' rel='noreferrer' className="text-white hover:text-gray-400">Resume</a></li>
						</ul>
                    </div>
                    <div className="mb-6 md:mb-0">
                        <h3 className="text-lg font-semibold mb-2">Social Media</h3>
                        <div className="flex space-x-4">
						<a href="https://www.linkedin.com/in/altaafali/" target='_blank' rel='noreferrer'>
								<img src='/assets/icons/linkedin.png' alt='' className='mr-3 w-6 h-6 object-contain' />
                        </a>
                        <a href="https://github.com/Altaaaf" target='_blank' rel='noreferrer'>
                                <img src='/assets/icons/github.png' alt='' className='mr-3 w-6 h-6 object-contain' />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="mt-10 border-t border-gray-700 pt-6 text-center">
                    <p className="text-white">© 2024 altaaf.dev. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
